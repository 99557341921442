<template>
  <Layout>
    <!-- <div class="mx-w mx-auto mb-3">
      <h1 class="display-3 mb-3">{{ $t('page.main') }}</h1>

      <div class="">
        <p>
          <b>Main</b> page...
        </p>
        <p>
          en: {{ $t('languages.en') }}
          <br />
          ru: {{ $t('languages.ru') }}
        </p>
        <div>
          <pre>authorized: {{ authorized }}</pre>
        </div>
        <div>
          <pre>user: {{ user }}</pre>
        </div>
        <div>
          <pre>userRoles: {{ userRoles }}</pre>
        </div>
      </div>

    </div> -->
  </Layout>
</template>

<script>
// import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import router from "@/router";
import Layout from "@/views/layouts/main";

export default {
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t('page.main'),
    };
  },
  components: {
    Layout,
  },
  computed: {
    // ...mapState(useGlobalStore, ['authorized', 'userRoles']),
  },
  beforeRouteEnter(to, from, next) {
    const store = useGlobalStore()

    // Admin redirect from Main -> admin/users
    if (store.authorized && store.userRoles?.includes("a")) {
      router.push("/admin/users");
    }

    // Company redirect from Main -> company/users
    if (store.authorized && store.userRoles?.includes("c")) {
      router.push("/company/users");
    }

  },
  data() {
    return {

    };
  },
  created() { },
  methods: {},
};
</script>
<style scoped lang="scss"></style>

